.center-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }
  
  .container-fluid{
    overflow-x: hidden; 
    overflow-y: hidden;
  }

  .recova-blue{
    color: #0A6095;
  }