.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.moving-tab {
  width: 344px !important;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0px;
  width: 100%;
  height: 20px;
  /* Set the fixed height of the footer here */
  line-height: 20px;
  /* Vertically center the text there */
  background-color: #f5f5f5;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.footer span {
  font-size: small;
}

/* .Toastify {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
} */
.modal-xl {
  max-width: 1140px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.hide {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.badge-default {
  background-color: #2bbbad !important;
  color: #fff !important;
}

.badge-light {
  background-color: #e0e0e0 !important;
  color: #000 !important;
}

.badge-warning {
  background-color: #fb3 !important;
}

.upload-wrapper {
  width: 100%;
  height: 96px;
  background: #F2F2F2;
  border: 1px dashed #0a6095;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  font-size: 14px;
}

.upload-wrapper:hover {
  cursor: pointer;
  opacity: 0.8;
}

.upload-wrapper input {
  height: 100%;
  width: 100%;
  opacity: 0;
}

.upload-wrapper input:hover {
  cursor: pointer;
}

.upload-wrapper label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.basic-input {
  height: 37px;
  width: 100%;
  background-color: #f5f8fa;
  border: 1px solid #e1e8ee;
  box-shadow: none;
  transition: all .2s ease-in-out;
  font-weight: 300;
  font-size: 12px;
  padding: 0.375rem 0.75rem;

}

.basic-input:focus {
  background-color: #fff;
  border-color: #1bb1f5;
  color: #495057;
  box-shadow: none;
  outline: 0;
}

.basic-input:disabled {
  background-color: #dfdee0;
  border-color: #c2c2c2;
  cursor: not-allowed;
}

.basic-input-area {
  height: 100px;
  width: 100%;
  background-color: #f5f8fa;
  border: 1px solid #e1e8ee;
  box-shadow: none;
  transition: all .2s ease-in-out;
  font-weight: 300;
  font-size: 12px;
  padding: 0.375rem 0.75rem;
}

.basic-input-area:focus {
  background-color: #fff;
  border-color: #1bb1f5;
  color: #495057;
  box-shadow: none;
  outline: 0;
}

.basic-input-area:disabled {
  background-color: #dfdee0;
  border-color: #c2c2c2;
  cursor: not-allowed;
}

.basic-select {
  height: 37px;
  width: 100%;
  border: 1px solid #e1e8ee;
  box-shadow: none;
  transition: all .2s ease-in-out;
  font-weight: 300;
  font-size: 12px;
  padding: 0.375rem 0.75rem;
  vertical-align: middle;
  background: #f5f8fa url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.basic-select:focus {
  background-color: #fff;
  border-color: #1bb1f5;
  color: #495057;
  box-shadow: none;
  outline: 0;
}

.text-wrap {
  text-wrap: wrap;
}

.wallet-container {
  margin: 20px;
}

.wallet-box {
  /* border-color: #ef7e21a1; */
  /* Orange color */
  padding: 10px 20px;
  /* Reduced vertical padding */
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.wallet-details {
  display: flex;
  align-items: center;
  flex-grow: 0.4;
}

.mr-2 {
  margin-right: 0.5rem;
  /* existing right margin */
  margin-left: 0.5rem;
  /* add left margin */
}

.wallet-title {
  font-size: 16px;
  /* Slightly smaller for reduced height */
  color: #ef7e21a1;
  /* White color for better contrast */
  margin-right: 20px;
}

.wallet-icon img {
  height: 30px;
  /* Reduced image size */
  margin-right: 20px;
}

.wallet-balance {
  font-size: 20px;
  /* Reduced font size */
  color: #ef7e21a1;
  /* White color for visibility */
  flex-grow: 1;
  text-align: right;
}

.wallet-preloader {
  display: flex;
  align-items: center;
  width: 30px;
  /* Reduced spinner size */
  height: 30px;
}

.spinner-layer {
  width: 30px;
  height: 30px;
}

.withdrawal-section {
  display: flex;
  align-items: right;
  flex-grow: 0;
}

.btn-withdraw {
  padding: 10px 15px;
  /* Reduced padding to decrease height */
  font-size: 14px;
  /* Smaller font size */
  background-color: #045e95;
  /* Deep purple background */
  color: #ffffff;
  /* White text */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.withdrawal-notice {
  margin-top: 5px;
  font-size: 10px;
  /* Smaller font size for notice */
  color: #ffffff;
  /* Adjusted for contrast */
  text-align: right;
  flex-grow: 1;
}

.react-wizard-container {
  background-color: #f7f7f7;
  border: 1px solid #e1e8ee;
  border-radius: 4px;
}

.react-wizard-nav {
  padding: 15px;
  border-bottom: 1px solid #e1e8ee;
}

.react-wizard-body {
  background-color: #ffffff;
  padding: 15px;
  padding-bottom: 50px;
}

.react-wizard-footer{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #e1e8ee;
}