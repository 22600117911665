/*
*
* ==================================================
* UNNECESSARY STYLE - JUST TO MAKE IT LOOK NICE
* ==================================================
*
*/
.countdown {
  text-transform: uppercase;
  font-weight: bold;
}

.countdown span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 3rem;
  margin-left: 0.8rem;
}

.countdown span:first-of-type {
  margin-left: 0;
}

.countdown-circles {
  text-transform: uppercase;
  font-weight: bold;
}

.countdown-circles span {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.countdown-circles span:first-of-type {
  margin-left: 0;
}

/*
  *
  * ==================================================
  * FOR DEMO PURPOSES
  * ==================================================
  *
  */
body {
  min-height: 100vh;
}

/* .bg-gradient-1 {
    background: #fc7e6d;
    background: -webkit-linear-gradient(to right, #7f7fd5, #86a8e7, #f2fdfd);
    background: linear-gradient(to right, #7f7fd5, #86a8e7, #f2fdfd);
  } */

.rounded {
  border-radius: 1rem !important;
}

.btn-demo {
  padding: 0.5rem 2rem !important;
  border-radius: 30rem !important;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  text-transform: uppercase;
  font-weight: bold !important;
}

.btn-demo:hover,
.btn-demo:focus {
  color: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.container {
  max-width: 1170px;
  margin: auto;
}
img {
  max-width: 100%;
}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%;
  border-right: 1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}
/* .srch_bar {
    display: inline-block;
    text-align: right;
    width: 60%; padding:
  } */

.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}
.chat_ib h5 span {
  font-size: 13px;
  float: right;
}
.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}
.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 0px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #9b9b9b;
  display: block;
  font-size: 12px;
  /* margin: 8px 0 0; */
}
.received_withd_msg {
  width: 60%;
}

.sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 0px 10px 5px 12px;
  width: 100%;
}
.outgoing_msg {
  /* overflow: hidden; */
  margin: 10px 0 10px;
}
.incoming_msg {
  /* overflow: hidden; */
  margin: 10px 0 10px;
}
.sent_msg {
  float: right;
  width: 55%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}
.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}
.messaging {
  padding: 0 0 50px 0;
}

.mesgs {
  height: 100%;
  min-height: 100%;
  margin: 0px;
  display: flex;
  float: left;
  padding: 0 0 0 15px;
  width: 100%;
}

.msg_history {
  /* height: 100%; */
  min-height: 100%;
  height: 715px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  /* flex-wrap: wrap; */
  flex: 1 0 auto;
}

.initiator {
  font-size: 12px;
}
