.navigation-body{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
}
.navigation-small-body{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}
.navigation{
    display:flex;
    justify-content: center;
    align-items: center;
    padding:15px;
    border-radius: 4px;
    cursor: pointer;
}
.navigation-small:hover {
    background:#03a9f4;
    color: #fff;
    transition: all 0.2s ease-in
}
.navigation:hover {
    background:#03a9f4;
    color: #fff;
    transition: all 0.2s ease-in
}
.navigation-small{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding:10px;
    border-radius: 4px;
    cursor: pointer;
}
.nav-active{
    background:#03a9f4;
    color: #fff;
}