.generatedrules .preset {
  /* padding: 15px; */
  text-align: center;
  border: 10px solid rgb(161, 180, 202);
}
.generatedrules .preset p {
  margin: 0px;
}
.approvable .step {
  padding: 1%;
  /* border-bottom: 5px groove #ddd; */
  display: flex;
  align-items: center;
}
.approvable .step:last-child {
  border-bottom: none;
}
.approvable .step .role {
  padding: 2%;
  margin: 5px 13px;
  display: inline-block;
  border-radius: 5px;
}
.approvable .step div[data-group="-1"] {
  color: #03032e;
  background-color: #f5dfdf;
  border: 1px solid #360101;
  margin-top: 15px;
  /* text-align: left !important; */
  font-weight: bold;
}
.approvable .step div {
  padding: 2%;
  color: #03032e;
  background-color: #d6dadd;
  border: 1px solid #001a2b;
  margin-top: 15px;
  /* text-align: left !important; */
  font-weight: bold;
}

.approvable .step div p {
  margin: 0px;
}

.block-example {
  width: 6rem;
  height: 4rem;
  margin: 0.5rem;
  background-color: #eee;
  display: inline-block;
}
.plus-item {
  font-style: italic;
  display: inline-block;
  text-align: center;
  border: 2px dashed #e7e6e6;
  border-radius: 5px;
  color: #e7e6e6;
  cursor: pointer;
}
.plus-item:hover {
  color: #d6dadd;
  border: 2px dashed #d6dadd;
  background-color: #e7e6e6;
}
/* .fa-plus-square:hover {
    color: #d6dadd !important;
  } */
.rule-card-body {
  /* padding: 1%;
    border-bottom: 5px groove #ddd; */
  display: flex;
  align-items: center;
}
.new-level {
  width: 100%;
  padding: 0;
  margin-top: 10px;
  font-size: 25px;
}
.new-role {
  width: 7%;
  font-size: 40px;
}
