.details-form {
  padding: 2.5em;
}

form {
  padding: 15px 0px 15px 0px;
}

.error {
  font-size: 0.9rem;
  color: #f44336;
  display: block;
  margin-bottom: 0px;
}

.clearfix {
  margin-bottom: 0px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #025f95 !important;
}

.container-fluid {
  overflow-x: hidden;
  overflow-y: hidden;
}
