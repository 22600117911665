.page-link {
  border: none;
}
a.page-link {
  padding-top: 1px;
}
a.page-link > i.fa-grip-lines-vertical {
  padding-top: 8px;
}

.sm-doc {
  font-size: x-small !important;
}

/* .table-responsive{
  overflow-x: unset;
} */
.table thead th {
  border-top: none;
  border-left: none;
  /* border-right: none; */
}

.filter-list-group-item {
  /* font-size: large;
    padding-top: 8px; 
    padding-bottom: 8px;
    background-color: rgb(248, 248, 248);
    border: rgb(252, 252, 252) solid 0.1px;

     */
  display: inline-block;
  color: #444;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  background: #ddd;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  vertical-align: middle;
  width: 100%;
  padding: 5px;
  text-align: center;
}
.filters-container {
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  border-left: none;
  border-right: none;
}
.filter-list-div {
  padding-left: 10px;
  padding-right: 10px;
}
/* .btn-outline-grey{
    border: 0.5px solid grey;
  } */
